<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">
          <router-link :to="{ name: 'users' }">Users</router-link>
        </li>
        <li class="breadcrumb-item active">Edit</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
        <div class="col-md-11">
          <h2 class="title2">Edit User</h2>
        </div>
        <div class="col-md-1 text-right">
          <router-link :to="{ name: 'users' }" class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <div v-if="this.$store.state.loader">
        <loader
          object="#f74b3d"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="80"
          disableScrolling="false"
          name="spinning"
        ></loader>
      </div>
      <form v-else @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="mb-3">
                  <label class="form-label">Employee</label>
                  <b-form-select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.name.$model"
                    :validator="$v.form.name"
                    @change="setUserData"
                    :options="employees"
                    :disabled="this.form.tempName != null ? true : false"
                  ></b-form-select>
                </div>
                <div class="mb-3">
                  <label for="firstName" class="form-label">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="$v.form.email.$model"
                    :validator="$v.form.email"
                    :readonly="this.form.email != null ? true : false"
                  />
                  <span class="error" v-if="!$v.form.email.required"
                    >Email is required</span
                  >
                </div>

                <div class="mb-3">
                  <label class="form-label">Account type</label>
                  <input
                    type="radio"
                    class=""
                    v-model="$v.form.acc_type.$model"
                    :validator="$v.form.acc_type"
                    value="2"
                    id="admin"
                  /><label for="admin">Admin</label>
                  <input
                    type="radio"
                    class=""
                    v-model="$v.form.acc_type.$model"
                    :validator="$v.form.acc_type"
                    value="1"
                    id="employee"
                  /><label for="employee">Employee</label>
                </div>

                <div class="mb-3">
                  <label class="form-label">Role</label>
                  <b-form-select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.role_id.$model"
                    :validator="$v.form.role_id"
                    :options="roles"
                  ></b-form-select>
                  <span class="error" v-if="!$v.form.role_id.required"
                    >Role is required</span
                  >
                </div>
                <div class="mb-3">
                  <label class="form-label">Status</label>
                  <b-form-select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.status.$model"
                    :validator="$v.form.status"
                    :options="status"
                  ></b-form-select>
                  <span class="error" v-if="!$v.form.status.required"
                    >Status is required</span
                  >
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Password</label>
                      <input
                        type="password"
                        class="form-control"
                        v-model="$v.form.password.$model"
                        :validator="$v.form.password"
                      />
                      <span class="error" v-if="$v.form.password.required"
                        >Password is required</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Confirm Password</label>
                      <input
                        type="password"
                        class="form-control"
                        v-model="$v.form.password_confirmation.$model"
                        :validator="$v.form.password_confirmation"
                      />
                      <span
                        class="error"
                        v-if="$v.form.password_confirmation.required"
                        >Confirm Password is required</span
                      >
                    </div>
                  </div>
                </div>
                <div class="text-right">
                  <button type="submit" class="btn btn-outline-primary space">
                    Update
                  </button>
                  <router-link
                    :to="{ name: 'users' }"
                    class="btn btn-outline-primary"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      form: {
        name: null,
        tempName: null,
        email: null,
        acc_type: null,
        role_id: null,
        status: null,
        ref: null,
        password: null,
        password_confirmation: null,
      },
      status: [
        {
          value: "1",
          text: "Enabled",
        },
        {
          value: "0",
          text: "Disabled",
        },
      ],
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      tempName: {},
      email: {
        required,
      },
      acc_type: {
        required,
      },
      role_id: {
        required,
      },
      status: {
        required,
      },
      password: {},
      password_confirmation: {},
    },
  },
  created() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadUsers");
  },
  mounted() {
    let empData = this.$store.state.users.users.filter((user) => {
      return user.id == this.$route.params.id;
    })[0];
    let empProfile = this.$store.state.users.employees.filter((emp) => {
      console.log("emp-id", empData.reference);
      return emp.id == empData.reference;
    })[0];
    console.log("empProfile", this.employees);
    this.form.tempName = empData.name;
    this.form.ref = empData.name;
    this.form.name = empData.reference;
    this.form.email = empProfile.emailaddress;
    this.form.acc_type = empData.acc_type;
    this.form.role_id = empData.role_id;
    this.form.status = empData.status;
  },
  computed: {
    roles() {
      return this.$store.state.users.roles.map((role) => {
        return {
          value: role.id,
          text: role.role_name,
        };
      });
    },
    employees() {
      return this.$store.state.users.employees.map((employee) => {
        return {
          value: employee.id,
          text: employee.lastname + ", " + employee.firstname,
        };
      });
    },
  },
  methods: {
    setUserData() {
      let empDetail = this.$store.state.users.employees.filter((emp) => {
        console.log(emp);
        return emp.id == this.form.name;
      })[0];
      this.form.email = empDetail.emailaddress;
      this.form.ref = this.form.name;
      this.$v.form.name.$model =
        empDetail.lastname + ", " + empDetail.firstname;
      console.log(this.$v.form.name);
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.form.ref = this.form.name;
      this.form.name = this.form.tempName;
      axios
        .post("users/update/user", this.form)
        .catch(({ response }) => {
          console.log(response);
          if (response.status == 422) {
            console.log(Object.values(response.data.errors));
          }
          this.flashMessage.error({
            message: "Something went wrong!",
            time: 3000,
          });
        })
        .then(({ data }) => {
          console.log(data);
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$router.push({ name: "users" });
          } else {
            this.flashMessage.error({
              message: data.data.error,
              time: 3000,
            });
          }
        });
    },
    onChangeFileUpload() {
      this.form.image = this.$refs.file.files[0];
    },
  },
};
</script>
